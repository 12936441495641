<template>
	<div class="main-content">
		<ktv-breadcrumb :title="$t('View Package', 'View Package')" :page="$t('Package', 'Package')" :folder="$t('Loan', 'Loan')" />
		<b-row>
			<b-col md="12 mb-30">
				<b-row>
					<b-col>
						<h1>{{ $t("Package Data", "Package Data") }}</h1>
					</b-col>
				</b-row>
				<b-row class="mb-4">
					<b-col>
						<router-link v-b-tooltip.hover :to="{ name: 'Loan Institution Data', params: { id: $route.params.institutionId } }">
							<ktv-back :label="$t('Back to package list', 'Back to package list')" />
						</router-link>
					</b-col>
				</b-row>
				<div>
					<b-card
						:header="$t('Loan Package Basic Data', 'Loan Package Basic Data')"
						align="left"
						border-variant="danger"
						header-bg-variant="primary"
						header-text-variant="white"
					>
						<b-alert v-if="is.loading" variant="primary" show> Loading... </b-alert>
						<basic-data v-if="!is.loading" :pkg="packageData" />
					</b-card>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	import { KtvBack } from "@/components"
	import { mapActions, mapState } from "vuex"
	import BasicData from "./_BasicData"

	export default {
		name: "LoanPackage",
		metaInfo: {
			title: "Loan Package",
		},
		components: { BasicData, KtvBack },
		data() {
			return {
				is: {
					loading: false,
				},
			}
		},
		computed: {
			...mapState("PACKAGE", {
				packageData: "package",
			}),
		},
		created() {
			if (this.$route.params.id) {
				this.getData()
			}
		},
		methods: {
			...mapActions({
				getPackage: "PACKAGE/getPackage",
			}),
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getPackage(this.$route.params.id)
					.then(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
		},
	}
</script>
