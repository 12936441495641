<template>
	<b-form v-if="localPackage">
		<ktv-accordion name="loanPackageInformation" :title="$t('Loan Package Information', 'Loan Package Information')">
			<template #content>
				<b-row v-if="$route.params.id">
					<ktv-input v-model="localPackage.loanPackageId" :label="$t('ID', 'ID')" readonly />
				</b-row>
				<b-row>
					<ktv-input v-model="localPackage.packageName" readonly :label="$t('Package Name', 'Package Name')" />
				</b-row>
				<b-row>
					<ktv-button-group v-model="localPackage.loanCostumerTypeId" :options="customerTypeOptions" :label="$t('Customer Type', 'Customer Type')" />
				</b-row>
				<b-row>
					<ktv-button-group v-model="localPackage.loanPackageTypeId" :options="packageTypeOptions" :label="$t('Package Type', 'Package Type')" />
				</b-row>
				<b-row>
					<ktv-checkbox-group
						v-model="localPackage.documentRequired"
						:label="$t('Required Documents', 'Required Documents')"
						:options="documentTypeOptions"
						disabled
					/>
				</b-row>
				<b-row>
					<b-col class="px-0">
						<ktv-input v-model="localPackage.margin" :label="$t('Margin', 'Margin')" type="number" min="0" readonly append="%" />
					</b-col>
					<b-col class="px-0">
						<ktv-input v-model="localPackage.provision" :label="$t('Provision', 'Provision')" type="number" min="0" readonly append="%" />
					</b-col>
					<b-col class="px-0">
						<ktv-input
							:value="
								localPackage.currencyIsoCode && localPackage.currencyName
									? `${localPackage.currencyIsoCode} - ${localPackage.currencyName}`
									: '-'
							"
							:label="$t('Currency', 'Currency')"
							readonly
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col class="px-0">
						<ktv-select v-model="localPackage.countryId" :options="countryOptions" readonly disabled :label="$t('Country', 'Country')" />
					</b-col>
					<b-col class="px-0">
						<ktv-select v-model="localPackage.provinceId" :options="provinceOptions" readonly disabled :label="$t('Province', 'Province')" />
					</b-col>
					<b-col class="px-0">
						<ktv-select v-model="localPackage.districtId" disabled :options="districtOptions" readonly :label="$t('District', 'District')" />
					</b-col>
				</b-row>
			</template>
		</ktv-accordion>
		<ktv-accordion
			v-if="localPackage.loanPackageTypeId === 155 || localPackage.loanPackageTypeId === 157"
			:title="$t('Loan Cash', 'Loan Cash')"
			class="mt-4"
			name="loanPackageCash"
		>
			<template #content>
				<b-row>
					<ktv-input v-model="totalCashAmountString" :label="$t('Cash Amount', 'Cash Amount')" type="currency" readonly />
				</b-row>
			</template>
		</ktv-accordion>
		<ktv-accordion
			v-if="localPackage.loanPackageTypeId === 156 || localPackage.loanPackageTypeId === 157"
			:title="$t('Loan Product', 'Loan Product')"
			class="mt-4"
			name="loanPackageProduct"
		>
			<template #content>
				<b-row>
					<b-col class="px-0">
						<ktv-select v-model="localPackage.kioskId" disabled readonly :options="kiosKOptions" :label="$t('Kiosk', 'Kiosk')" />
					</b-col>
					<b-col class="px-0">
						<ktv-input v-model="totalProductAmountString" :label="$t('Product Amount', 'Product Amount')" type="currency" readonly />
					</b-col>
				</b-row>
				<ktv-table
					id="package-products"
					:columns="columns"
					:filter="false"
					:is-loading="is.loading"
					:rows="filteredData"
					:search-enabled="false"
					:search-on-enter="false"
					:search-placeholder="$t('Search by product name or id', 'Search by product name or id')"
					:total-rows="packageProducts ? filteredData.length : 0"
					min-height="250px"
					mode="client"
					@on-search="onSearch"
				>
					<template #columns="{ props }">
						{{ $t(props.column.label, props.column.label) }}
					</template>
					<template #rows="{ props }">
						<span v-if="props.column.field === 'ProductImages'">
							<span v-if="props.row.ProductImages">
								<ktv-image-viewer
									:file-name="getProductImage(props.row.ProductImages, 'filename')"
									:image="getProductImage(props.row.ProductImages, 'path')"
									thumbnail-class="w-75"
								/>
							</span>
							<span v-else>-</span>
						</span>
						<span v-else-if="props.column.field === 'SalePrice'">{{ props.row.SalePrice | Number }}</span>
						<span v-else-if="props.column.field === 'totalPrice'">{{ (parseInt(props.row.SalePrice, 10) * props.row.quantity) | Number }}</span>
					</template>
				</ktv-table>
			</template>
		</ktv-accordion>
	</b-form>
</template>

<script>
	import { filter, findWhere, map, pluck } from "underscore"
	import { mapActions, mapGetters, mapState } from "vuex"
	import { KtvAccor as KtvAccordion, KtvButtonGroup, KtvCheckboxGroup, KtvImageViewer, KtvInput, KtvSelect, KtvTable } from "@/components"

	export default {
		name: "LoanPackageViewBasicData",
		metaInfo: {
			title: "Package View - Basic Data",
		},
		components: { KtvAccordion, KtvButtonGroup, KtvCheckboxGroup, KtvImageViewer, KtvInput, KtvSelect, KtvTable },
		props: {
			pkg: {
				default: () => {},
				required: false,
				type: Object,
			},
		},
		data() {
			return {
				columns: [
					{
						field: "ProductID",
						label: "ID",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "ProductImages",
						label: "Image",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "Name",
						label: "Product Name",
						thClass: "text-left",
					},
					{
						field: "ProductUnitName",
						label: "Unit Type",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "quantity",
						label: "Product Quantity",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "SalePrice",
						label: "Price",
						tdClass: "text-right",
						thClass: "text-right",
						width: "200px",
					},
					{
						field: "totalPrice",
						label: "Total",
						tdClass: "text-right",
						thClass: "text-right",
						width: "200px",
					},
				],
				countryOptions: [],
				deleteProductId: null,
				districtOptions: [],
				is: {
					loading: false,
					showing: {
						addModal: false,
						deleteDialog: false,
					},
				},
				kiosKOptions: [],
				localPackage: null,
				params: {
					limit: 10,
					order: null,
					page: 1,
					search: null,
				},
				provinceOptions: [],
				searchText: null,
				updateProduct: null,
			}
		},
		computed: {
			...mapGetters({
				packageProducts: "PACKAGE/packageProducts",
				userId: "AUTHDATA/userId",
			}),
			...mapState("OTHERS", ["countryList", "customerTypeList", "districtList", "documentTypeList", "kiosKList", "packageTypeList", "provinceList"]),
			customerTypeOptions() {
				return map(this.customerTypeList, (customerType) => {
					return customerType && customerType.value === "sme"
						? {
							text: this.$t("SME", "SME"),
							value: parseInt(customerType.entityListOfValueId, 10),
						}
						: {
							text: this.$t(this.capitalize(customerType.value), this.capitalize(customerType.value)),
							value: parseInt(customerType.entityListOfValueId, 10),
						}
				})
			},
			documentTypeOptions() {
				return map(this.documentTypeList, (documentType) => {
					return documentType && documentType.value === "Marriage Certificae"
						? {
							text: this.$t("Marriage Certificate", "Marriage Certificate"),
							value: parseInt(documentType.entityListOfValueId, 10),
						}
						: {
							text: this.$t(this.capitalize(documentType.value), this.capitalize(documentType.value)),
							value: parseInt(documentType.entityListOfValueId, 10),
						}
				})
			},
			filteredData() {
				return this.searchText
					? filter(this.packageProducts, (product) => {
						const premiumName = String(product.Name).toLowerCase()
						const premiumProductId = String(product.ProductID).toLowerCase()
						const searchText = String(this.searchText).toLowerCase()

						return premiumName.indexOf(searchText) > -1 || premiumProductId.indexOf(searchText) > -1
					})
					: this.packageProducts
			},
			isCountryLoaded() {
				return this.countryList && this.countryList.length > 0 && this.localPackage && this.localPackage.countryId
			},
			packageTypeOptions() {
				return map(this.packageTypeList, (packageType) => {
					return {
						text: this.$t(packageType.value, packageType.value),
						value: parseInt(packageType.entityListOfValueId, 10),
					}
				})
			},
			totalCashAmountString() {
				return this.$options.filters.Number(this.localPackage.totalCashAmount)
			},
			totalProductAmountString() {
				return this.$options.filters.Number(this.localPackage.totalProductAmount)
			},
		},
		watch: {
			countryList: {
				deep: true,
				handler() {
					this.getCountryOptions()
				},
				immediate: true,
			},
			districtList: {
				deep: true,
				handler() {
					this.getDistrictOptions()
				},
				immediate: true,
			},
			isCountryLoaded: {
				handler(newValue, oldValue) {
					if (newValue && newValue !== oldValue) {
						const country = findWhere(this.countryList, { entityCountryID: String(this.localPackage.countryId) })

						if (country && country.countryCode) {
							this.getProvinceList(country.countryCode)
						}
					}
				},
				immediate: true,
			},
			kiosKList: {
				deep: true,
				handler() {
					this.getKiosKOptions()
				},
				immediate: true,
			},
			"localPackage.districtId": {
				deep: true,
				handler(newValue, oldValue) {
					if (newValue !== oldValue) {
						this.getKiosKList(this.localPackage.districtId)
					}
				},
				immediate: true,
			},
			"localPackage.kioskId": {
				deep: true,
				handler(newValue, oldValue) {
					if (newValue && newValue !== oldValue) {
						if (this.$route.params.id && this.localPackage.product) {
							this.getProductList({
								kioskId: this.localPackage.kioskId,
								product: this.localPackage.product,
							})
						}
					}
				},
				immediate: true,
			},
			"localPackage.provinceId": {
				deep: true,
				handler(newValue, oldValue) {
					if (newValue !== oldValue) {
						this.getDistrictList(this.localPackage.provinceId)
					}
				},
				immediate: true,
			},
			pkg: {
				deep: true,
				handler(pkg) {
					if (pkg) {
						const requiredDocuments = pluck(pkg.documentRequired, "documentType")
						this.localPackage = Object.assign({}, pkg, { documentRequired: requiredDocuments })
					}
				},
				immediate: true,
			},
			provinceList: {
				deep: true,
				handler() {
					this.getProvinceOptions()
				},
				immediate: true,
			},
		},
		created() {
			this.getCountryList()
			this.getCustomerTypeList()
			this.getDocumentTypeList()
			this.getPackageTypeList()
		},
		beforeDestroy() {
			this.reset()
		},
		methods: {
			...mapActions({
				addProduct: "PACKAGE/addProduct",
				createPackage: "PACKAGE/createPackage",
				getCountryList: "OTHERS/getCountryList",
				getCustomerTypeList: "OTHERS/getCustomerTypeList",
				getDistrictList: "OTHERS/getDistrictList",
				getDocumentTypeList: "OTHERS/getDocumentTypeList",
				getKiosKList: "OTHERS/getKiosKList",
				getPackageTypeList: "OTHERS/getPackageTypeList",
				getProductList: "PACKAGE/getProductList",
				getProvinceList: "OTHERS/getProvinceList",
				resetPackage: "PACKAGE/resetPackage",
				resetProductList: "PACKAGE/resetProductList",
				updatePackage: "PACKAGE/updatePackage",
			}),
			capitalize(text) {
				if (!text) {
					return ""
				}

				let arr = []
				let exludeWords = ["of", "the", "by", "with"]
				arr = text.split(" ")

				return arr
					.map((word, i) => {
						return exludeWords.includes(word) && i !== 0 ? [word] : word.charAt(0).toUpperCase() + word.slice(1)
					})
					.join(" ")
			},
			getCountryOptions() {
				this.countryOptions = map(this.countryList, (country) => {
					return {
						text: country.country,
						value: parseInt(country.entityCountryID, 10),
					}
				})
			},
			getDistrictOptions() {
				this.districtOptions = map(this.districtList, (district) => {
					return {
						text: district.district,
						value: parseInt(district.entityDistrictID, 10),
					}
				})
			},
			getKiosKOptions() {
				this.kiosKOptions = map(this.kiosKList, (kiosK) => {
					return {
						text: kiosK.PersonName,
						value: parseInt(kiosK.EntityID, 10),
					}
				})
			},
			getProductImage(imagesInString, type = "path") {
				const imagesArray = imagesInString.split(",")

				if (type === "filename") {
					return imagesArray[0] ? imagesArray[0] : null
				}

				return imagesArray[0] ? `${process.env.VUE_APP_URL_FR_PRODUCT_IMAGE}${imagesArray[0]}` : null
			},
			getProvinceOptions() {
				this.provinceOptions = map(this.provinceList, (province) => {
					return {
						text: province.province,
						value: parseInt(province.entityProvinceID, 10),
					}
				})
			},
			onKiosKSelected({ text }) {
				this.localPackage = {
					...this.localPackage,
					kioskName: text,
				}
			},
			onSearch($event) {
				const self = this

				this.is = {
					...this.is,
					loading: true,
				}
				this.searchText = $event

				setTimeout(() => {
					self.is = {
						...self.is,
						loading: false,
					}
				}, 500)
			},
			reset() {
				this.resetPackage()
				this.resetProductList()
			},
		},
	}
</script>
